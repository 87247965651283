$click-to-configure-highlight-color: #fff !default;
$click-to-configure-opacity: 0.5 !default;
$click-to-configure-contrast-opacity-ratio: 2.5 !default;
$click-to-configure-animate: true !default;
$click-to-configure-animation-speed: 1.8s !default;
$click-to-configure-animation-duration: infinite !default;

@include keyframes(highlight) {
  0% {
    opacity: 0.01;
    filter: alpha(opacity=1);
  }
  50% {
    opacity: $click-to-configure-opacity;
    filter: alpha(opacity=$click-to-configure-opacity*100);
  }
  100% {
    opacity: 0.01;
    filter: alpha(opacity=1);
  }
}

@include keyframes(highlightHighContrast) {
  0% {
    opacity: 0.01;
    filter: alpha(opacity=1);
  }
  50% {
    opacity: ($click-to-configure-opacity / $click-to-configure-contrast-opacity-ratio);
    filter: alpha(opacity=($click-to-configure-opacity / $click-to-configure-contrast-opacity-ratio) * 100);
  }
  100% {
    opacity: 0.01;
    filter: alpha(opacity=1);
  }
}

// display highlight styles
g.fc-area-ca {
  fill: $click-to-configure-highlight-color;
  opacity: 0;
}


@if $click-to-configure-animate {
  g.fc-area-ca.highlighted {
    animation: highlight $click-to-configure-animation-speed;
  }
  g.fc-area-ca:hover {
    animation: highlight $click-to-configure-animation-speed $click-to-configure-animation-duration;
  }

  g.fc-area-ca.highlighted.high-contrast {
    animation: highlightHighContrast $click-to-configure-animation-speed;
  }
  g.fc-area-ca.high-contrast:hover {
    animation: highlightHighContrast $click-to-configure-animation-speed $click-to-configure-animation-duration;
  }
}
@else {
  g.fc-area-ca:hover {
    opacity: $click-to-configure-opacity;
  }
}