@import "./mixins/main";

.fc-attribute-selector {
  overflow: hidden;

  .fc-dialog & {
    padding: 1em;
  }
}


.fc-attribute-title, .fc-facet-title {
  margin: 0.4em 0;
  font-size: 1rem;
  line-height: 2;

  .fc-swatch {
    width: 2rem;
    height: 2rem;
    margin: -0.5em 0.4em -0.5em 0;
  }
}

.fc-attribute-title-info-icon {
  padding: 1px;
  display: inline-block;
  line-height: 0;
  vertical-align: top;
}

.fc-swatch-group, .fc-facet-swatch-group {
  @extend %plain-list;
  @extend %unselectable;
  line-height: 0;
}

.fc-attribute-value-swatch, .fc-facet-value-swatch {
  @extend %z-depth-1;
  display: inline-block;
  background-color: #fff;
  margin: 0.1em;
  line-height: 0;
  cursor: pointer;

  &.fc-swatch-selected {
    @extend %z-depth-7;
    background-color: hsl(189, 100%, 90%);
  }

  &:hover {
    @extend %z-depth-6;
  }
}

.fc-av-unselectable {
  opacity: 0.5;
  pointer-events: none;
}

.fc-swatch {
  @extend %swatch;
  width: 2.25em;
  height: 2.25em;
  line-height: 0;
}

.fc-attribute-value-swatch-ugc {
  position: relative;

  .fc-swatch {
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    .fc-swatch-remove {
      display: block;
    }
  }
}

.fc-swatch-remove {
  display: none;
  position: absolute;
  height: 50%;
  width: 50%;
  background-color: red;
}

.fc-swatch-title {
  display: none;
}

.fc-facet-swatch-title {
  display: inline-block;
  line-height: 1;
  padding: 0.4em;
}

.fc-ugc-file-input {
  width: 0;
  height: 0;
  display: none;
}

.fc-pointer {
  cursor: pointer;
}

.fc-dropdown, .fc-facet-dropdown {
  padding: 0.2em;
}

.fc-radio, .fc-facet-radio {
  @extend %unselectable;
  width: 33%;
  display: inline-block;

  span, input, label {
    cursor: pointer;
  }
}

.fc-button-selector {
  display: inline-block;
  margin: 2px;
  width: 71px;
  overflow: visible;
  @extend %z-depth-1;
  .fc-button-selected {
    background-color: #ddd;
  }
  .fc-button {
    height: 36px;
    display: block;
    text-align: center;
    line-height: 2.2;
  }
}

.fc-facet-selectors {
  @extend %plain-list;
}

.fc-facet-selector {
  margin: 0;
}

.fc-quantity-title {
  display: inline-block;
  padding: 0.4em;
}

.fc-ugc-editor {
  .fc-button {
    display: inline-block;
    line-height: 2.2;
    padding: 0.5em;
  }
}

.fc-terms-and-conditions {
  line-height: 2.2;
  padding: .5em;
  .fc-button {

    &.disabled {
      @extend %unselectable;
      color: rgba(0,0,0,.5);
    }
  }
  .fc-terms-cancel-button {
    margin: 0 auto;
    display: block;
  }

}

.fc-ca-fieldset,
.fc-facet-fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

.fc-text-restriction-label {
  display: none;
}

.fc-ca-legend,
.fc-facet-legend {
  width: 0;
  display: inline-block;
  height: 0;
  overflow: hidden;
  margin: 0;
}