@import "bourbon";
@import "./main";


.fc-ripple-effect {
  display: none;
  position: absolute;
  top: 100%;
  left: 100%;
  width: 0px;
  height: 0px;
  line-height: 0;
  @include transform(translate(-50%, -50%));
  background-color: #000;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 0 5px 5px #000;

  &.fc-show {
    display: block;
    @include animation(fc-ripple-anim 0.4s $ease-in-sine)
  }

}

@include keyframes(fc-ripple-anim) {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.15;
  }
  100% {
    opacity: 0;
    width: 300px;
    height:  300px;
  }
}

%text-button {
  @extend %unselectable;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-align: center;

  position: relative;
  overflow: hidden;

  /* &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  } */

  &.fc-disabled {
    color: #aaa;
    cursor: default;

    &:hover {
      background-color: inherit;
    }
  }
}


%icon-button {
  @extend %text-button;
  font-size: 1rem;
  padding: 1em;
  height: 1em;
  font-weight: bold;
  width: 1em;
  min-width: 1em;
}

.fc-button {
  @extend %text-button;
}

.fc-button-raise {
  @include animation(fc-raise-anim 0.5s);
}

@include keyframes(fc-raise-anim) {
  0% {
    box-shadow: 0 0.075rem 0.3rem 0 rgba(0, 0, 0, 0.12);
  }
  25% {
    box-shadow: 0 0.3125rem 1.25rem 0 rgba(0, 0, 0, 0.19);
  }
  100% {
    box-shadow: 0 0.075rem 0.3rem 0 rgba(0, 0, 0, 0.0);
  }
}
