$carousel-font-path: "../fonts/" !default;
$carousel-dot-size: 10px !default;
$carousel-arrow-color: #ccc !default;

@import "carousel.scss";
@import "rotator.scss";

/* Arrows */

.fc-carousel-previous,
.fc-carousel-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $opacity-on-hover;
      }
    }
    &.fc-carousel-disabled:before {
        opacity: $opacity-not-active;
    }
}
.fc-carousel-previous:before, .fc-carousel-next:before {
    font-family: $carousel-font-family;
    font-size: 20px;
    line-height: 1;
    color: $carousel-arrow-color;
    opacity: $opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fc-carousel-previous {
    left: 25px;
    [dir="rtl"] & {
        left:  auto;
        right: 25px;
    }
    &:before {
        content: $carousel-previous-character;
        [dir="rtl"] & {
            content: $carousel-next-character;
        }
    }
}
.fc-carousel-next {
    right: 25px;
    [dir="rtl"] & {
        left:  25px;
        right: auto;
    }
    &:before {
        content: $carousel-next-character;
        [dir="rtl"] & {
            content: $carousel-previous-character;
        }
    }
}

.fc-display-image-loading-state-wrapper {
  display: inline-block;
}

.fc-debug-network-images {
  @include clearfix;
  background: #ccc;
  text-align: center;
  padding: 0;

  h4 {
    margin: 0 0 0.25rem;
    padding: 0.25rem;
    font-size: 12px;
    text-transform:uppercase;
    background:#fafafa;
    border-top:3px solid #333;
  }

  figure {
    display: inline-block;
    width: 30%;
    position: relative;
    margin: 0 1% 1rem 1%;
    line-height:1;
    padding: 0;

    figcaption {
      position: absolute;
      top:100%;
      right: 0;
      left: 0;
      font-size: 11px;
      background: rgba(0,0,0,0.65);
      color: #fff;
      text-transform: uppercase;
    }
  }

  img {
    max-width: 100%;
    width: auto;
    background-color: red;
    @include transition (opacity 0.6s ease-in-out);
    &.fc-display-loading {
      // opacity: 0.5;
      // @include filter(grayscale(0%) contrast(100%) blur(8px));
    }
    &.fc-display-loaded {
      opacity: 1;
    }

    &.fc-rotator-priority-high {
      background-color: green;
    }

    &.fc-rotator-priority-medium {
      background-color: orange;
    }

  }
}
