@import "../mixins/main";
@import "./mixins";

// Styles used for the Attribute Debug test app
.fc-attribute-debug {

  margin: 0;
  padding: 0;
  color: #333;
  font-family: "Source Sans Pro" sans-serif;
  font-size: 16px;
  background-color: hsl(189, 10%, 90%);



  .attribute-debug {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //height: 100vh;
    flex-direction: row;
  }


  .attribute-display {
    flex: 1;
    padding: 1em;
    display: flex;
    flex-direction: column;

    .fc-display-picture {
      max-width: 100%;
      position: relative;
      top: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

  }

  .fc-display {
    flex: 1;
  }


  .explain {
    background-color: #fff;
    overflow-y: scroll;
    height: 150px;

    h4 {
      margin: 1em 1em 0;
    }

    pre {
      white-space: pre-wrap;
      overflow-x: hidden;
      margin: 0;
      padding: 1em;
      font-size: 0.8em;
    }
  }

  .attribute-list {
    flex: 1;
    padding: 1em;
    margin: 0;
    overflow-y: scroll;
  }

  h1, h2, h3, h4, h5 {
    font-weight: 300;
  }

  .value-list {
    padding: 0 0 1em;
  }

  .value-list li {
    display: inline-block;
    margin: 0.2em;
  }

  .attribute-value {
    @extend %value;
  }

  .attribute-value-selected {
    @extend %z-depth-2;
  }

  .value-swatch {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 0;
    margin-right: 0.5em;
  }

  .attribute-value-disabled {
    color: rgba(0.2, 0.2, 0.2, 0.4);
  }

  .facet-hidden, .facet-hidden .facet-value {
    color: rgba(0.2, 0.2, 0.2, 0.4);
    cursor: default;
  }


  .facet-value {
    @extend %value;
  }

  .facet-value-selected {
    @extend %z-depth-2;
  }

  .fc-app ul {
    list-style: none;
  }

  .fc-app > ul {
    //padding: 0;
  }

  .attribute-group-title {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    font-size: 1.4rem;
    background-color: #fff;

    cursor: pointer;
    -wekbit-user-select: none;
    -moz-user-select: none;
  }

  .attribute-group-list {
    padding: 0.5em;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
  }

  .attribute-group-item {
    @extend %z-depth-1;
    background-color: #fff;
    margin: 0.25rem 0;
  }

  .attribute-title {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    cursor: pointer;
  }


  dl {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  dt, dd {
    padding: 0.5em;
    margin: 0;
  }
}
