.fc-accordion {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.fc-accordion-panel-header {
  background: #dedede;
  padding: .59em;
  border: 1px solid #a0a0a0;
  margin: 0;
}

.fc-accordion-panel {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.fc-accordion-panel-body {
  padding: .5em .5em 1em 0.5em
}

.fc-accordion-panel.fc-is-closed {

  .fc-accordion-panel-body-revealer {
    max-height: 0;
    transition: max-height .6s ease-in-out;
  }

  .fc-accordion-panel-body {
    opacity: .3;
    transition: opacity .4s ease-out;
  }

  .fc-accordion-panel-header {
    color: #666;
    border: 1px solid #ccc;

    &:hover {
      cursor: pointer;
      background: #d5d5d5;
    }
  }
}

.fc-accordion-panel.fc-is-open {

  .fc-accordion-panel-header {
    background: #ccc;
    color: #333;
    opacity: 1;
    transition: all .5s ease-out;
  }

  .fc-accordion-panel-body-revealer {
    max-height: 400px;
    overflow: auto;
    transition: all .7s ease-in-out .6s;
  }
}
