@import "./mixins/main";

.fc-dialog {
  @extend %z-depth-3;
  @extend %sheet;
  box-sizing: border-box;
  overflow: hidden;
  @include transition-property(top, left, width, height, transform);
  @include transition-duration(250ms);
  @include transition-delay(50ms);
  @include transition-timing-function(ease-out);

  .fc-button {
    position: static;
    overflow: initial;
  }
}


.fc-dialog-header {
  padding: .5em 1em;
  background-color: rgba(250,250,250,0.8);
  h2 {
    margin: 0;
    display: inline;
    font-weight: normal;
  }
  &.fc-dialog-no-title {
  }
}


.fc-dialog-body {
  overflow: auto;
}


.fc-dialog-footer {
  @extend %clearfix;
  text-align: right;

  .fc-form-error {
    color: red;
    line-height: 3;
  }

  .fc-button {
    display: inline-block;
    padding: 1em;
    text-transform: uppercase;
    font-weight: bold;
  }
}


.fc-dialog-close {
  padding: 0.5em;
  display: inline-block;
  vertical-align: middle;
  float: right;
  cursor: pointer;
}
