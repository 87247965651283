$secondary-text-color: #aeaeae;

.fc-attribute-header {
  display: inline-block;
  vertical-align: top;
  //height: 1rem;
  line-height: 1;
  margin: 0;
  padding: .5rem 0;
  width: 100%;

  .fc-swatch {
    display: inline-block;
    width: 2.3rem;
    height: 2.3rem;
    margin: -0.5em 0.5em -0.5em 0;
    transition: background-color .5s;
  }
}

.fc-attribute-header-info-icon {
  padding: 1px;
  display: inline-block;
  line-height: 0;
  vertical-align: top;
}

.fc-attribute-header-swatch-name {
  margin-left: .5rem;
  font-weight: normal;
  color: $secondary-text-color;

  &::before {
    content: "(";
  }
  &::after {
    content: ")";
  }
}


.fc-attribute-header-index-display {
  display: inline-block;
  font-weight: normal;
  font-size: 0.8em;
  padding: 0.2em;
  float: right;
}



.fc-attribute-header-index {
  &:empty {
    display: none;
  }
  &::before {
    content: "(";
  }
  &::after {
    content: "/";
  }
}

.fc-attribute-header-index-count {
  &:empty {
    display: none;
  }
  &::after {
    content: ")";
  }
}
