@import "bourbon";

$root2: 0.707em;
$icon-color: #333;

%icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
  vertical-align: baseline;

  .fc-disabled & {
    opacity: 0.4;
  }
}

.fc-icon-down-arrow {
  @extend %icon;

  &:after {
    content: " ";
    position: absolute;
    top: 0em;
    left: 0.15em;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 0.7em solid $icon-color;
    border-right: 0.7em solid transparent;
    @include transform(rotate(-45deg));
  }
}

.fc-icon-next {
  @extend %icon;
  $line-width: 0.2em;

  &:before {
    content: " ";
    position: absolute;

    top: (1em - $root2) / 2;
    left: 0em;
    height: $root2 - $line-width;
    width: $root2 -  $line-width;
    border-right: $line-width solid $icon-color;
    border-bottom: $line-width solid $icon-color;
    @include transform-origin(50% 50%);
    @include transform(rotate(-45deg));
  }
}

.fc-icon-prev {
  @extend %icon;
  $line-width: 0.2em;

  &:before {
    content: " ";
    position: absolute;

    top: (1em - $root2) / 2;
    left: 0.25em;
    height: $root2 - $line-width;
    width: $root2 -  $line-width;
    border-right: $line-width solid $icon-color;
    border-bottom: $line-width solid $icon-color;
    @include transform-origin(50% 50%);
    @include transform(rotate(-225deg));
  }
}


.fc-icon-menu {
  @extend %icon;

  &:after {
    content: " ";
    position: absolute;
    background-color: $icon-color;
    top: 0.42em;
    left: 0.1em;
    width: 0.8em;
    height: 0.16em;
    box-shadow: 0 -0.32em 0 0 $icon-color, 0 0.32em 0 0 $icon-color;

  }

}


.fc-icon-close {
  @extend %icon;

  $line-width: 0.125em;

  &:after {
    content: " ";
    position: absolute;
    background-color: $icon-color;

    top: (1.0em - $line-width) / 2;
    left: 0;
    height: $line-width;
    width: 1em;
    @include transform(rotate(-45deg));
  }

  &:before {
    content: " ";
    position: absolute;
    background-color: $icon-color;

    top: (1.0em - $line-width) / 2;
    left: 0;
    height: $line-width;
    width: 1em;
    @include transform(rotate(-135deg));
  }
}

.fc-icon-adding,
.fc-icon-sending {
  @extend %icon;

  $line-width: 0.2em;

  &:before {
    content: " ";
    box-sizing: border-box;
    width: 0.9em;
    height: 0.9em;
    top: 0.05em;
    left: 0.05em;
    position: absolute;
    border: $line-width solid $icon-color;
    border-radius: 50%;
  }

  &:after {
    content: " ";
    width: $line-width;
    height: 1em;
    position: absolute;
    left: (1em - $line-width) / 2;
    background-color: #fff;

    @include animation(fc-spin 2s linear 0s infinite);
  }
}

@include keyframes(fc-spin) {
  0% {
    @include transform(rotate(0deg));
  }
  50% {
    @include transform(rotate(180deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}

.fc-icon-checked {
  @extend %icon;

  $line-width: 0.2em;

  &:before {
    content: " ";
    box-sizing: border-box;
    border-right: $line-width solid $icon-color;
    border-bottom: $line-width solid $icon-color;
    position: absolute;
    left: 0.3em;
    width: 0.45em;
    height: 0.85em;
    @include transform(rotate(45deg));
  }
}
