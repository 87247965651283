$carousel-font-path: "../fonts/" !default;
$carousel-dot-size: 10px !default;
$carousel-arrow-color: #ccc !default;

@import "configure_display/index.scss";

.fc-display {
  &.fc-display-loading {
    opacity: 0.5;
    @include filter(grayscale(0%) contrast(100%) blur(8px));
  }

  .fc-configure-display {

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .carousel-next {
      right: 10px;
    }
    .carousel-previous {
      left: 10px;
    }
    .carousel-track {
      overflow: hidden;
    }
  }
}
