/* SNAPSHOTS */
$snapshots-border-color: #eaeaea;
$snapshots-overlay-max-height: 380px;

// @include keyframes(highlight) {
//   0% {
//     background-color: #e5e5e5;
//   }
//   100% {
//     background-color: none;
//   }
// }

.fc-snapshots {
  display: inline-block;
  position: relative;

  div, h2, ul, li, span, button {
    box-sizing:border-box;
  }

  .fc-snapshots-call-to-action-wrapper {
    padding:8% 15%;
    font-size: 90%;
    text-align:center;
  }

  .fc-snapshots-content {
    background: #fff;
    max-height: $snapshots-overlay-max-height;
    min-width: 280px;
    width: auto;
    position: absolute;
    bottom: 100%;
    left: 0;
    border: 1px solid $snapshots-border-color;

    .fc-snapshots-content-header {
      height: 30px;

      .fc-snapshots-content-header-text { // <h2>
        font-size: 100%;
        margin: 0;
        padding: 8px 0 0 10px;
        white-space: nowrap;
      }

      .fc-button-pair {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
        line-height: 0;
      }
    }
  }

  .fc-snapshots-list { // <ul>
    max-height:350px;
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .fc-snapshots-entry { // <li>
      display: block;
      position: relative;
      line-height: 1;
      padding: 10px;
      border-bottom: 1px solid $snapshots-border-color;

      &:last-of-type {
        border-bottom: 0;
      }

      .fc-snapshots-remove-entry-icon {
        display: none;
      }

      // Implementers...
      &.fc-snapshots-entry-duplicated {
        // @include animation(highlight 1s $ease-in-sine);
      }

      .fc-snapshots-entry-image-wrapper {
        display: block;
        text-align: center;
        font-size: 0;
        border: 1px solid $snapshots-border-color;
        padding: 3px 0;

        img {
          margin: 0 auto;
          display: block;
          max-height: none;
          height: auto;
          width: auto;
          cursor: pointer;
        }
      }

      .fc-snapshots-entry-info {
        display: block;
        font-size: 80%;
        white-space: nowrap;
        padding: 3% 0 0;
        @extend %clearfix;

        .fc-snapshots-entry-time {
          display:inline-block;
          float: left;
        }

        .fc-snapshots-remove-wrapper {
          display:inline-block;
          float: right;
        }
      }
    }
  }

  &.fc-snapshots-count-0 .fc-snapshots-list-badge-counter {
    visibility: hidden;
  }

  .fc-snapshots-controls {
    .fc-snapshots-take-wrapper {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      .fc-button-pair {
        display: none;
      }

      .fc-snapshots-take-entry-icon {
        display: block;
      }
    }

    .fc-snapshots-view-wrapper {
      display: inline-block;
      vertical-align:middle;
      margin: 0 12px;

      .fc-button-pair {
        display: inline-block;
      }

      .fc-snapshots-view-button {
        display: inline-block;
      }

      .fc-snapshots-list-badge-counter {
        position: absolute;
        background-color: red;
        color: white;
        font-weight: bold;
        font-size: 0.8em;
        border-radius: 30px;
        box-shadow: 1px 1px 1px gray;
        margin: -10px 0 0 3px;
        padding: 2px 4px;
      }
      .fc-snapshots-list-badge-counter {
        display: inline;
      }
    }
  }
}
