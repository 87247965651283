@import "bourbon";
@import "./mixins/main";
@import "./mixins/button";

.fc-pager-header {
  @extend %z-depth-2;
  @extend %clearfix;
  @extend %unselectable;
  position: relative;
  padding: 0;
  height: 3rem;
  white-space: nowrap;
}

.fc-pager-current-name {
  display: inline-block;
  vertical-align: top;
  height: 1rem;
  line-height: 1;
  margin: 0;
  padding: 1rem 0;

  .fc-swatch {
    display: none;
    margin: -0.5em 0.5em -0.5em 0;
  }
}

.fc-pager-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  line-height: 0;
  background-color: #fff;
}

.fc-pager-wrapper-previous {
  right: auto;
  position: relative;
  float: left;
}

.fc-pager-page-number {
  display: inline-block;
  font-weight: normal;
  font-size: 0.8em;
  padding: 0.2em;
}

.fc-pager-prev, .fc-pager-next {
  @extend %icon-button;
  display: inline-block;
  line-height: 0.8;
  height: 1em;
}

.fc-pager-pulldown {
  display: inline-block;
}

.fc-pager-pulldown-button {
  @extend %icon-button;
  line-height: 1;
}

.fc-pager-attribute-list {
  @extend %z-depth-2;
}

.fc-pager-pulldown-list {
  @extend %plain-list;
  overflow: hidden;

  li {
    padding: 0;
    display: block;
    min-width: 8em;
  }

}

.fc-pager-pulldown-item {
  padding: 0.4em;
}

.fc-attribute-list-header {
  text-align: left;

  .fc-attribute-header-index-display {
    display: none;
  }
}

.fc-pager-pullout {
  position: absolute;
  left: 50%;
  margin-top: -1em;
  @include transform(translate(-50%, -100%));
  box-shadow: 0 -0.125rem 0.5rem 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  width: 3em;
  //cursor: n-resize;
}

.fc-pager-body {
  overflow: hidden;
  position: relative;

  & > span {
    @extend %clearfix;
    display: block;
    white-space: nowrap;
    vertical-align: top;
    height: 100%;
  }
}

.fc-pager-page {
  @extend %clearfix;
  box-sizing: border-box;
  display: block;
  white-space: normal;
  z-index: 1;
  @include transition-property(transform);
  @include transition-duration(200ms);
  @include transition-timing-function(ease-out);
}


.fc-pager-right-enter {
  @include transform(translateX(80%));

  &.fc-pager-right-enter-active {
    @include transform(translateX(0));
  }
}


.fc-pager-right-leave {
  position: absolute;
  top: 0;
  left: 0;
  @include transform(translateX(0));

  &.fc-pager-right-leave-active {
    @include transform(translateX(-100%));
  }
}

.fc-pager-left-enter {
  @include transform(translateX(-100%));

  &.fc-pager-left-enter-active {
    @include transform(translateX(0));
  }
}


.fc-pager-left-leave {
  position: absolute;
  top: 0;
  left: 0;
  @include transform(translateX(0));

  &.fc-pager-left-leave-active {
    @include transform(translateX(100%));
  }
}

