@import "settings/prefixer";
@import "addons/prefixer";
@import "css3/filter";
@import "css3/transition";
@import "css3/transform";


@function grayscale($value) {
  @return #{ "grayscale(" + $value + ")" };
}

@mixin filter($properties) {
  // Vendor prefixing using node-bourbon
  @include prefixer(filter, $properties, webkit moz spec);
}



.fc-display-rotator {
  width: 100%;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;

  &.fc-hover {
    cursor: move; /* fallback for IE */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  &.fc-drag {
    cursor: move; /* fallback for IE */
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }


  canvas {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  .fc-preloader {
    opacity: 1;
    margin: 70px auto;
    border: 6px solid rgba(0, 0, 0, .1);
    border-top-color: rgba(0, 0, 0, .4);
    border-radius: 100%;
    height: 50px;
    width: 50px;
    animation: spin .6s infinite linear;
    position: relative;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @include transition (opacity 0.6s ease-in-out);

  &.fc-display-loading {
    // opacity: 0.5;
    // @include filter(grayscale(0%) contrast(100%) blur(8px));
  }

  &.fc-display-loaded {
    opacity: 1;
  }
}
