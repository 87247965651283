@import "./test/main";
@import "./a11y";
@import "./icons";
@import "./form";
@import "./selector";
@import "./pager";
@import "./display";
@import "./click_to_configure";
@import "./dialog";
@import "./add_to";
@import "./info";
@import "./sharing";
@import "./facebook";
@import "./attribute_header";
@import "./accordion";
@import "./ugcEditor";
@import "./clipartEditor";
@import "./navFlyout";
@import "./tooltips";
@import "./snapshots";

.fc-app {
  font-family: sans-serif;
}

.fc-error {
  color: red;
}
