// from google's style guide
%z-depth-0 {
  box-shadow: 0 0.0 0 0 rgba(0, 0, 0, 0.0);
}

%z-depth-1 {
  box-shadow: 0 0.075rem 0.3rem 0 rgba(0, 0, 0, 0.12);
}

%z-depth-2 {
  box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.16);
}

%z-depth-3 {
  box-shadow: 0 0.3125rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}

%z-depth-4 {
  box-shadow: 0 0.875rem 1.75rem 0 rgba(0, 0, 0, 0.25);
}

%z-depth-5 {
  box-shadow: 0 1.1875rem 2.375rem 0 rgba(0, 0, 0, 0.30);
}

%z-depth-6 {
  box-shadow: 0.2rem 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.4);
}

%z-depth-7 {
  box-shadow: 0.2rem 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.5);
}

%sheet {
  background-color: #fff;
  border-radius: 2px;
}

%clearfix {
  &:before, &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

%unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


%ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%plain-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

%swatch {
  display: inline-block;
}
