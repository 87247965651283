.fc-nav-flyout { // <ul>
  margin:0;
  padding:0;
  list-style:none;
  position:relative;

  .fc-nav-flyout-ca { // <li>
    display:block;
    border-bottom:1px dotted #eaeaea;

    .fc-nav-flyout-header {
      position:relative;
      padding:5% 10%;
      cursor:pointer;

      &:before {
        display:none;
        width: 0;
        height: 0;
        content: '';
        position: absolute;
      }
    }

    .fc-nav-flyout-panel {
      display:none;
      position:absolute;
      background:#fff;
    }

    .fc-nav-flyout-panel-header {
      position:relative;
      padding:5% 10%;

      h2 {
        margin: 0;
        display: inline;
        font-weight: normal;
      }

      .fc-nav-flyout-panel-header-title {
      }

      .fc-nav-flyout-panel-header-close {
        display:none;
        vertical-align: middle;
        position: absolute;
        cursor: pointer;
        top: 15px;
        right: 15px;
      }
    }

    &.fc-nav-flyout-active { // .fc-flyout-ca.fc-nav-flyout-active
      .fc-nav-flyout-header {
        &:before {display:block;}
      }

      .fc-nav-flyout-panel {
        display:block;
      }

      .fc-nav-flyout-panel-header-close {
        display:inline-block;
      }
    }
  }
  .fc-nav-flyout-ca {
    .fc-nav-flyout-header {
      padding:3% 5%;
    }
    .fc-nav-flyout-panel-header {
      padding:3% 5%;
    }
  }

  &.fc-nav-flyout-reveal-left {
    .fc-nav-flyout-header:before {
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-left: 10px solid #ffffff;
      right: 100%;
      top:50%;
      -moz-transform:translateY(-50%);
      -webkit-transform:translateY(-50%);
      transform:translateY(-50%);
    }

    .fc-nav-flyout-panel {
      right:100%;
      top:0;
      bottom:0;
      margin-right:10px;
    }
  }

  &.fc-nav-flyout-reveal-right {
    .fc-nav-flyout-header:before {
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-right: 10px solid #ffffff;
      left: 100%;
      top: 50%;
      -moz-transform:translateY(-50%);
      -webkit-transform:translateY(-50%);
      transform:translateY(-50%);
    }

    .fc-nav-flyout-panel {
      left:100%;
      top: 0;
      bottom:0;
      margin-left:10px;
    }
  }


  .fc-nav-flyout-panel-header-selected-av {
    display: none;
    font-size: 0.7em;
    padding-left: 5%;
    opacity: 0.8;
    &:before {
      content: "("
    }
    &:after {
      content: ")"
    }
  }
}



.fc-nav-flyout-ag {

  @include user-select(none);

  h3.fc-attribute-header {
    font-size: 1rem;
    font-weight: normal;
  }
  .fc-nav-flyout-active {
    h3.fc-attribute-header {
      font-weight: bold;
    }
  }

  .fc-accordion-panel {
    display: inline;
  }

  .fc-nav-flyout-ag-cas-wrapper {
    display: inline;
  }

  .fc-accordion-panel-header {
    display: block;
    text-align: left;
  }

  .fc-nav-flyout {
    width: 100%;
  }

  .fc-accordion-panel-body {
    padding: 0;
  }

  .fc-accordion-panel-header:before {
    width: 0;
    height: 0;
    content: '';
    position: relative;
    display: inline-block;
    top: 50%;
    left: 95%;
    border-right: none;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.4rem solid #000;
  }

  .fc-nav-flyout-reveal-left .fc-nav-flyout-panel {
    right: 100%;
    top: -100%;
    bottom: auto;
    display: block;
    margin-right: 10px;
  }

  .fc-is-closed {
    .fc-nav-flyout {
      display: none;
    }
    .fc-accordion-panel-body-revealer {
      opacity: 0;
    }
  }

  .fc-is-open {
    .fc-nav-flyout-panel {
      display: none;
    }
    .fc-nav-flyout-active {
      .fc-nav-flyout-panel {
        display: block;
      }
    }
    .fc-nav-flyout {
      display: inline-block;
    }
    .fc-accordion-panel-header:before {
      transform: rotate(90deg);
    }
    .fc-accordion-panel-body {
      transition: none;
    }
    .fc-accordion-panel-body-revealer {
      overflow: visible;
      transition: none;
    }
  }

  .fc-nav-flyout-ca {
    position: relative;
    .fc-nav-flyout-header .fc-attribute-header .fc-swatch {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .fc-attribute-header-swatch-name {
    display: none;
  }

}






