@import "bourbon";
@import "./mixins/main";

.fc-share-dialog-list {
  @extend %plain-list;
}

.fc-share-dialog-item {
  line-height: 1;
  padding: 1em;
}

.fc-share-dialog {
  padding: 1em;
  @extend %clearfix;
}

.fc-label, .fc-label-text {
  display:block;
}

.fc-dialog-body .fc-input {
  padding: 0 1em;
  input,
  textarea {
    width: 100%;
  }
}

.fc-dialog-submit, .fc-dialog-cancel {
  padding: 1em;
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1.25;
  float: right;
}

.fc-share-message {
  width: 100%;
  height: 5.6em;
  resize: none;
  margin-bottom: 1em;
}

.fc-facebook-image {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  float: left;
  margin: 0 1em;
  width: 100px;
  height: 100px;

  span {
    font-size: 4em;
    opacity: 0.2;
  }

  img {
    max-width: 100%;
  }
}

.fc-facebook-title {
  font-weight: bold;
  margin: 0 1em 1em 0;
}

.fc-facebook-summary {
  margin: 0 1em 1em 0;
}
