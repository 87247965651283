@import "./mixins/main";

.fc-input input, .fc-input textarea {
  border: 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 8px;
  padding: 0.4em 0;
  font-size: 1.2em;
  resize: none;

  &:focus {
    outline: 0;
    border-bottom: 2px solid hsl(189, 80%, 50%);
    margin-bottom: 7px;
  }

  &:invalid, &.fc-invalid {
    border-bottom: 2px solid hsl(0, 80%, 50%);
    margin-bottom: 7px;
  }

  .fc-api-2018 &.fc-waiting-for-validation {
    border-bottom: 2px solid hsl(60, 100%, 50%);
    margin-bottom: 7px;
  }
}

.fc-button-pair {
  button {
    display: none;
  }
  div.fc-button {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
}



.fc-button .fc-icon {
  margin-right: 0.4em;
}


.fc-form-error {
  padding: 10px;
  color: #ff0000;
}
