.fc-ugc-editor-dialog {

}

.fc-ugc-description {
  padding: 10px 0;
}

.fc-ugc-editor-container {

}

.fc-ugc-button-container {

}

.fc-ugc-toolbar{

}

.fc-ugc-rotate-clockwise :before{
  content: "\21bb";
  margin: 0 5px 0 0;
}

.fc-ugc-rotate-counterclockwise :before{
  content: "\21ba";
  margin: 0 5px 0 0;
}

.fc-ugc-zoom-in :before{
  content: "+";
  margin: 0 5px 0 0;
}

.fc-ugc-zoom-out :before{
  content: "-";
  margin: 0 5px 0 0;
}

.fc-ugc-button-delete {
  display : inline-block;
  padding: 10px;
}

.fc-ugc-button-save {
  display : inline-block;
  padding: 10px;
}