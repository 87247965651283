@charset "UTF-8";
@import "settings/prefixer";
@import "addons/prefixer";
@import "css3/animation";
@import "css3/transition";
@import "css3/transform";
@import "css3/filter";
@import "css3/keyframes";
@import "css3/flex-box";

// Default Variables

$carousel-font-path: "../fonts/" !default;
$carousel-font-family: "carousel" !default;
$carousel-loader-path: "./" !default;
$carousel-arrow-color: white !default;
$carousel-dot-color: black !default;
$carousel-dot-color-active: $carousel-dot-color !default;
$carousel-previous-character: '\2190' !default;
$carousel-next-character: '\2192' !default;
$carousel-dot-character: '\2022' !default;
$carousel-dot-size: 6px !default;
$opacity-default: .75;
$opacity-on-hover: 1;
$opacity-not-active: .25;

@function grayscale($value) {
  @return #{ "grayscale(" + $value + ")" };
}

@mixin filter($properties) {
  // Vendor prefixing using node-bourbon
  @include prefixer(filter, $properties, webkit moz spec);
}


.carousel-track {

  @include transition (opacity 0.6s ease-in-out);

  .fc-display-loading {
    // opacity: 0.5;
    // @include filter(grayscale(0%) contrast(100%) blur(8px));
  }

  .fc-display-loaded {
    opacity: 1;
  }


  .fading-enter {
    position: absolute;
    opacity: 0.01;
    transition: opacity .5s ease-in;
  }
  .fading-enter.fading-enter-active {
    opacity: 1;
  }
  .fading-leave {
    position: absolute;
    opacity: 1;
    transition: opacity .5s ease-in;
  }
  .fading-leave.fading-leave-active {
    opacity: 0.01;
  }

  .sliding-enter {
    @include animation(backSlideIn 2s both ease);
    border: 1px solid green;
    // @include animation-direction(alternate-reverse);
  }
  .sliding-enter.sliding-enter-active {
    // @include animation-direction(alternate-reverse);
  }
  .sliding-leave {
    border: 1px solid red;
    @include animation(backSlideOut 2s both ease);
  }
  .sliding-leave.sliding-leave-active {
    opacity: 0.01;
  }

}


@include keyframes(backSlideOut) {
  from {
    opacity: .8;
    @include transform(translate3d(0, 0px, 0px));
  }
  to {
    opacity: .3;
    @include transform(translate3d(-100%, 0px, 0px));
  }
}

@include keyframes(backSlideIn) {
  from {
    opacity: .5;
    @include transform(translate3d(300%, 0px, 0px));
  }
  to {
    opacity: 1;
    @include transform(translate3d(200%, 0px, 0px));
  }
}


/*
@include keyframes(backSlideOut) {
  0%, 25% {
    opacity: .5;
    @include transform(translateZ(-500px));
  }
  75% {
    opacity: .5;
    @include transform(translateZ(-500px) translateX(-100%));
  }
  100% {
    opacity: .5;
    @include transform(translateZ(-500px) translateX(-100%));
  }
}

@include keyframes(backSlideIn) {
  0%, 25% {
    opacity: .5;
    @include transform(translateZ(-500px) translateX(100%));
  }
  75% {
    opacity: .5;
    @include transform(translateZ(-500px));
  }
  100% {
    opacity: 1;
    @include transform(translateZ(0) translateX(0));
  }
}
*/


@function carousel-image-url($url) {
    @return url($carousel-loader-path + $url);
}

@function carousel-font-url($url) {
    @return url($carousel-font-path + $url);
}

/* Slider */

.carousel-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.carousel-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    .carousel-loading & {
        background: #fff carousel-image-url("ajax-loader.gif") center center no-repeat;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.carousel-slider .carousel-list,
.carousel-track,
.carousel-slide,
.carousel-slide img {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.carousel-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .carousel-loading & {
        visibility: hidden;
    }
}
.carousel-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.carousel-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .carousel-initialized & {
        display: block;
    }

    .carousel-loading & {
        visibility: hidden;
    }

    .carousel-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

/* Icons */
@if $carousel-font-family == "carousel" {
  @font-face {
      font-family:"carousel";
      src:    carousel-font-url("carousel.eot");
      src:    carousel-font-url("carousel.eot?#iefix") format("embedded-opentype"),
              carousel-font-url("carousel.woff") format("woff"),
              carousel-font-url("carousel.ttf") format("truetype"),
              carousel-font-url("carousel.svg#carousel") format("svg");
      font-weight: normal;
      font-style: normal;
  }
}


/* Dots */

.carousel-slider {
    margin-bottom: 30px;
}
.carousel-dots {
    position: absolute;
    bottom: -40px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0;
            font-size: 0;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                  opacity: $opacity-on-hover;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $carousel-dot-character;
                width: 20px;
                height: 20px;
                font-family: $carousel-font-family;
                font-size: $carousel-dot-size;
                line-height: 20px;
                text-align: center;
                color: $carousel-dot-color;
                opacity: $opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

        }

        &.carousel-active button:before {
            color: $carousel-dot-color-active;
            opacity: $opacity-default;
        }
    }
}


