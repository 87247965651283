
%value {
  @extend %z-depth-1;
  @extend %ellipsis;
  display: inline-block;
  width: 9em;
  padding: 0.2em 0.5em;
  cursor: pointer;

}
