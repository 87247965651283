.configure-display-webgl {
  position: relative;
}

.fc-clipart-editor-swatch-selector {
  position: relative;
  .fc-swatch {
    background-size: cover;
    background-position: center;
  }
}

.fc-clipart-editor-container {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.fc-clipart-editor-backdrop {
  position: absolute;
  background-color: #ccc;
  opacity: 0.7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}