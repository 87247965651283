/**
 * Base tooltip styles. If you need to override any of these, do it with
 * another specific targeting class below (eg: "fc-tooltip-nav-ca").
 *
 * @note: Several of these styling rules are setup how they are due to
 * previously having used "react-tooltip" library; which implemented the
 * ::before and ::after stuff for arrows, as well as all the margin offsets for
 * specific placement directions. There are definitely cleaner ways to do this,
 * but since some implementations are expecting these styles as-is, we have to
 * honor them :(.
 */
$tooltip-opacity:             0.9;
$tooltip-max-width:           280px;
$tooltip-transition-duration: 0.1s;

// tooltip type dark
$tooltip-type-dark-bg:        #222;
$tooltip-type-dark-color:     #fff;

// tooltip type light
$tooltip-type-light-bg:       #fff;
$tooltip-type-light-color:    #222;

@mixin tooltip-type($color) {
  &.fc-tooltip-place-top::after {
    border-top: 6px solid $color;
  }
  &.fc-tooltip-place-bottom::after {
    border-bottom: 6px solid $color;
  }
  &.fc-tooltip-place-left::after {
    border-left: 6px solid $color;
  }
  &.fc-tooltip-place-right::after {
    border-right: 6px solid $color;
  }
}

// Ensure the tooltip root is always on the left of the page,
// otherwise we could end up w/ scenarios where the .fc-tooltip
// gets scrunched width-wise and doesn't calculate correctly
.fc-tooltip-container {
  position: absolute;
  left: 0;
}

.fc-tooltip-container, .fc-tooltip {
  pointer-events: none;
}

// WARNING: DO NOT ADD transition: ... PROPERTIES UNLESS YOU PLAN TO UPDATE
// THE TESTS TO ACCOUNT FOR THE TIMING DELAYS (which influence the results of
// .getBoundingClientRect() when called on a node during a transition).
.fc-tooltip {
  // todo: make 'type-dark' bg/color the defaults?
  position: fixed;
  display: inline-block;
  max-width: $tooltip-max-width;
  z-index: 99;
  border-radius: 3px;
  font-size: 13px;
  padding: 8px 21px;
  box-sizing: border-box;
  margin: 0;
  visibility: hidden;
  opacity: 0;

  &::before, &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }

  &.fc-tooltip-type-dark {
    background: $tooltip-type-dark-bg;
    color: $tooltip-type-dark-color;
    @include tooltip-type($tooltip-type-dark-bg);
  }

  &.fc-tooltip-type-light {
    background: $tooltip-type-light-bg;
    color: $tooltip-type-light-color;
    @include tooltip-type($tooltip-type-light-bg);
  }

  &.fc-tooltip-show {
    visibility: visible;
    opacity: $tooltip-opacity;
  }

  hr {
    border: 0;
    border-top: 1px dotted #555555;
  }

  // placement classes
  &.fc-tooltip-place-top {
    &:before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      bottom: -8px;
      left: 50%;
      margin-left: -10px;
    }
    &:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -6px;
      left: 50%;
      margin-left: -8px;
    }
  }
  &.fc-tooltip-place-bottom {
    &:before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      top: -8px;
      left: 50%;
      margin-left: -10px;
    }
    &:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      top: -6px;
      left: 50%;
      margin-left: -8px;
    }
  }
  &.fc-tooltip-place-left {
    &:before {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      right: -8px;
      top: 50%;
      margin-top: -5px;
    }
    &:after {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      right: -6px;
      top: 50%;
      margin-top: -4px;
    }
  }
  &.fc-tooltip-place-right {
    &:before {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      left: -8px;
      top: 50%;
      margin-top: -5px;
    }
    &:after {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      left: -6px;
      top: 50%;
      margin-top: -4px;
    }
  }
}

/*---------------------------------
Specifically targeted tooltip types
---------------------------------*/

/**** EXTENDED SWATCH TOOLTIPS *****/
.fc-swatch-tooltip-image {
  display: inline-block;
  width: 100px;
  height: 100px;
  vertical-align: middle;
}

.fc-swatch-tooltip-name {
  line-height: 15px;
}

.fc-swatch-tooltip-description {
  line-height: 15px;
}

/**** ATTRIBUTE HEADER INFO TOOLTIPS *****/
.fc-attribute-header-info-tooltip-name {
  line-height: 15px;
}

.fc-attribute-header-info-tooltip-description {
  line-height: 15px;
}

/**** ATTRIBUTE SELECTOR INFORMATION TOOLTIPS  *****/
.fc-attribute-selector-info-tooltip-name {
  line-height: 15px;
}

.fc-attribute-selector-info-tooltip-description {
  line-height: 15px;
}

/**** DISPLAY HIGHLIGHTS  *****/
.fc-tooltip-display-highlight {
  display:table;
  width:100%;
  height:100%;

  .fc-tooltip-display-highlight-inner {
    display:table-cell;
    width:100%;
    height:100%;
    vertical-align:middle;
    text-align:center;
    line-height:1;
  }

  .fc-tooltip-display-highlight-swatch {
    display:inline-block;
    width:30px;
    height:30px;
    vertical-align:middle;
  }

  .fc-tooltip-display-highlight-name {
    display:inline-block;
    padding:2px 0 0 8px;
  }
}
