.fc-facebook-user-info {
  padding: 15px;
  position: relative;

  .fc-facebook-user-name {
    position: absolute;
    top: 10px;
    left: 75px;
  }

  .fc-fb-logout {
    font-size: 12px;
    cursor: pointer;
    display: block;
    margin-top: 6px;
    &:hover {
      text-decoration: underline;
    }
  }
  .fc-fb-custom-login-button {
    position: absolute;
    right: 20px;
    top: 38px;
    font-size: 26px;
    cursor: pointer;
    color: #ccc;
  }
}
